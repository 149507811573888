import { parseContainerBookmark, prepareQueryParamsForContainerRequest } from '~/utils/container.util';
import type { ISingleContainerApiResponse, ISimilarContainersQueryParams, IGetJsonPreviewParameter } from '~/types/single-container';

export const useSingleContainerStore = defineStore('singleContainerStore', {
  state: () => ({
    id: '',
    json: {},
    live_map_markers_json: {},
    container: null,
    containerRoute: null,
    bill_of_lading_bookmark_id: null,
    singleContainerPageConfig: null,
    org: {
      id: null,
      name: null,
      is_co2_emissions_enable: null,
      is_peta_enabled: null,
    },
  }),
  actions: {
    setContainerDetails(response: ISingleContainerApiResponse) {
      if (response) {
        const cbolb = response.cached_bill_of_lading_bookmarks;
        this.bill_of_lading_bookmark_id = cbolb.json.bill_of_lading_bookmark?.id || cbolb.json.bill_of_lading?.id;
        this.container = parseContainerBookmark(response.indexed_json);
        this.containerRoute = response.containerRoute;
        this.id = cbolb.id;
        this.json = cbolb.json;
        this.live_map_markers_json = cbolb.live_map_markers_json;
        this.singleContainerPageConfig = response.singleContainerPageConfig;
        this.org = response.org;
      }
    },
    async getSeaRoutes(id: string) {
      const url = `/api/sea_routes?id=${id}`;
      return $fetch(url, { method: 'GET' });
    },
    fetchSimilarContainers(routeQuery: Partial<ISimilarContainersQueryParams>) {
      const queryParams: string = prepareQueryParamsForContainerRequest(routeQuery);
      const userStore = useUserStore();
      const runtimeConfig = useRuntimeConfig();
      const url: string = `${runtimeConfig.public.BUI_URL}bill-of-lading-tracking/?org_id=${userStore.activeOrgId}&${queryParams}`;
      return $fetch(url, { method: 'GET' }) as any;
    },
    getJsonPreview(data: IGetJsonPreviewParameter) {
      const runtimeConfig = useRuntimeConfig();
      const { $ofetch }: any = useNuxtApp();
      return $ofetch(`${runtimeConfig.public.API_URL2}orgs/${data.orgId}/eta/tracking/bill-of-lading-bookmarks/${data.id}`);
    },
  },
});
